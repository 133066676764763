import React, { useEffect, useState } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components

import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { BACKEND_URL, BACKENDImage_URL } from "../../env.js";
import CreateUpdateInstagram from "./CreateUpdateInstagram.js";

const useStyles = makeStyles(componentStyles);

const Instagram = ({ AllInstagram, GetAllInstagram }) => {
    const classes = useStyles();

    const [openModel, setOpenModel] = useState(false);

    const [UpdateId, setUpdateId] = useState("");

    const handleClickOpen = () => {
        setOpenModel(true);
    };

    const handleClose = () => {
        setUpdateId("");
        setOpenModel(false);
    };

    useEffect(() => {
        GetAllInstagram();
    }, []);
    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Instagrams"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                        action={
                            <Button variant="outlined" onClick={handleClickOpen}>
                                Create Instagram
                            </Button>
                        }
                    ></CardHeader>
                    {AllInstagram && AllInstagram?.length >= 1 ? (
                        <TableContainer>
                            <Box component={Table} alignItems="center" marginBottom="0!important">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            classes={{
                                                root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                            }}
                                        >
                                            ID
                                        </TableCell>
                                        <TableCell
                                            classes={{
                                                root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                            }}
                                        >
                                            Image
                                        </TableCell>
                                        {/* <TableCell
											classes={{
												root: classes.tableCellRoot + " " + classes.tableCellRootHead
											}}
										>
											Total SubCategories
										</TableCell> */}
                                        <TableCell
                                            classes={{
                                                root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                            }}
                                        >
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {AllInstagram?.map((cat, i) => {
                                        return (
                                            <TableRow key={cat.id}>
                                                <TableCell
                                                    classes={{
                                                        root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
                                                    }}
                                                    component="th"
                                                    variant="head"
                                                    scope="row"
                                                >
                                                    <Box alignItems="center" display="flex">
                                                        <Box display="flex" alignItems="flex-start">
                                                            <Box fontSize=".875rem" component="span">
                                                                {cat.id}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell classes={{ root: classes.tableCellRoot }}>
                                                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                        {/* {cat?.name} */}
                                                        <img src={`${BACKENDImage_URL}${cat.Image}`} width={40} height={40} style={{ borderRadius: "5px" }} />
                                                    </Box>
                                                </TableCell>
                                                {/* <TableCell classes={{ root: classes.tableCellRoot }}>
													<Box paddingTop=".35rem" paddingBottom=".35rem">
														{cat.SubCategory.length || 0}
													</Box>
												</TableCell> */}
                                                <TableCell classes={{ root: classes.tableCellRoot }}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box
                                                            component="span"
                                                            marginRight=".5rem"
                                                            onClick={() => {
                                                                setOpenModel(true);
                                                                setUpdateId(cat.id);
                                                            }}
                                                        >
                                                            <Edit style={{ width: "20px", height: "20px" }} />
                                                        </Box>
                                                        <Box
                                                            component="span"
                                                            marginRight=".5rem"
                                                            onClick={() =>
                                                                Swal.fire({
                                                                    title: "Are you sure?",
                                                                    text: "You won't be able to revert this!",
                                                                    icon: "warning",
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: "#3085d6",
                                                                    cancelButtonColor: "#d33",
                                                                    confirmButtonText: "Yes, delete it!",
                                                                    preConfirm: () => {
                                                                        axios
                                                                            .delete(
                                                                                `${BACKEND_URL}/instagram/${cat?.id}`,
                                                                                {
                                                                                    headers: {
                                                                                        token: localStorage.getItem("Token")
                                                                                    }
                                                                                }
                                                                            )
                                                                            .then((res) => {
                                                                                console.log(res);
                                                                                GetAllInstagram();
                                                                            })
                                                                            .catch((res) => console.log(res));
                                                                    }
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        Swal.fire(
                                                                            "Deleted!",
                                                                            "Your Category has been deleted.",
                                                                            "success"
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            <Delete style={{ color: "red", width: "20px", height: "20px" }} />
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Box>
                        </TableContainer>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Instagram to Show</h1>
                        </div>
                    )}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={3} color="primary" variant="outlined" />
                    </Box>
                </Card>
                <CreateUpdateInstagram open={openModel} handleClose={handleClose} UpdateId={UpdateId} />
            </Container>
        </>
    );
};

export default withAppContext(Instagram);
