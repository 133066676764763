// import React, { useEffect, useState } from "react";

// // @material-ui/core components
// import Box from "@material-ui/core/Box";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardHeader from "@material-ui/core/CardHeader";
// import Container from "@material-ui/core/Container";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Pagination from "@material-ui/lab/Pagination";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// // @material-ui/icons components

// import Edit from "@material-ui/icons/Edit";
// // core components
// import Header from "../../components/Headers/Header.js";

// import componentStyles from "../../assets/theme/views/admin/tables.js";
// import { withAppContext } from "../../contextApi/Auth/index.js";

// import axios from "axios";
// import "sweetalert2/src/sweetalert2.scss";
// import { BACKEND_URL } from "../../env.js";
// import { Button } from "antd";

// const useStyles = makeStyles(componentStyles);

// const Order = () => {
//     const classes = useStyles();
//     const [AllOrder, setAllOrder] = useState([]);
//     const [statusOptions] = useState(["pending", "shipped", "delivered", "cancelled"]); // Update with your status options

//     useEffect(() => {
//         const GetAllOrder = () => {
//             axios
//                 .get(`${BACKEND_URL}/orders`, {
//                     headers: {
//                         token: localStorage.getItem("Token"),
//                     },
//                 })
//                 .then((res) => {
//                     console.log(res.data, "order");
//                     setAllOrder(res?.data);
//                 })
//                 .catch((err) => console.log(err));
//         };
//         GetAllOrder();
//     }, []);

//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
//     };

//     const handleStatusChange = (orderId, newStatus) => {
//         axios
//             .put(
//                 `${BACKEND_URL}/orders/${orderId}`,
//                 { status: newStatus },
//                 {
//                     headers: {
//                         token: localStorage.getItem("Token"),
//                     },
//                 }
//             )
//             .then((res) => {
//                 // Update the state with the new status
//                 setAllOrder((prevOrders) =>
//                     prevOrders.map((order) =>
//                         order.id === orderId ? { ...order, status: newStatus } : order
//                     )
//                 );
//             })
//             .catch((err) => console.log(err));
//     };

//     return (
//         <>
//             <Header />
//             <Container
//                 maxWidth={false}
//                 component={Box}
//                 marginTop="-6rem"
//                 classes={{ root: classes.containerRoot }}
//             >
//                 <Card classes={{ root: classes.cardRoot }}>
//                     <CardHeader
//                         className={classes.cardHeader}
//                         title="Available Orders"
//                         titleTypographyProps={{
//                             component: Box,
//                             marginBottom: "0!important",
//                             variant: "h3"
//                         }}
//                     />
//                     {AllOrder && AllOrder?.length >= 1 ? (
//                         <TableContainer>
//                             <Box component={Table} alignItems="center" marginBottom="0!important">
//                                 <TableHead>
//                                     <TableRow>
//                                         {/* Table Headers */}
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order ID</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order Date</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Email</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Phone Number</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Profit Margin</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>City</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Total Amount</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Products</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Status</TableCell>
//                                         <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>View Product</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {AllOrder?.map((cat) => (
//                                         <TableRow key={cat.orderId}>
//                                             <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootBodyHead}>
//                                                 <Box alignItems="center" display="flex">
//                                                     <Box display="flex" alignItems="flex-start">
//                                                         <Box fontSize=".875rem" component="span">{cat.orderId}</Box>
//                                                     </Box>
//                                                 </Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{formatDate(cat?.orderDate)}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.email}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.phoneNumber}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.profileMargin || 0}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.city}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.totalAmount}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">{cat.products?.length}</Box>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Select
//                                                     value={cat.status}
//                                                     onChange={(e) => handleStatusChange(cat.id, e.target.value)}
//                                                     variant="outlined"
//                                                     fullWidth
//                                                 >
//                                                     {statusOptions.map((status) => (
//                                                         <MenuItem key={status} value={status}>
//                                                             {status}
//                                                         </MenuItem>
//                                                     ))}
//                                                 </Select>
//                                             </TableCell>
//                                             <TableCell className={classes.tableCellRoot}>
//                                                 <Box paddingTop=".35rem" paddingBottom=".35rem">
//                                                     <Button>View Product</Button>
//                                                 </Box>
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Box>
//                         </TableContainer>
//                     ) : (
//                         <div
//                             style={{
//                                 width: "100%",
//                                 display: "flex",
//                                 alignContent: "center",
//                                 alignItems: "center",
//                                 justifyContent: "center"
//                             }}
//                         >
//                             <h1>No Order to Show</h1>
//                         </div>
//                     )}
//                     <Box
//                         classes={{ root: classes.cardActionsRoot }}
//                         component={CardActions}
//                         justifyContent="flex-end"
//                     >
//                         <Pagination count={3} color="primary" variant="outlined" />
//                     </Box>
//                 </Card>
//             </Container>
//         </>
//     );
// };

// export default withAppContext(Order);
import React, { useEffect, useState } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

import axios from "axios";
import "sweetalert2/src/sweetalert2.scss";
import { BACKEND_URL } from "../../env.js";

const useStyles = makeStyles(componentStyles);

const Order = () => {
    const classes = useStyles();
    const [AllOrder, setAllOrder] = useState([]);
    const [statusOptions] = useState(["pending", "shipped", "delivered", "cancelled"]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const GetAllOrder = () => {
            axios
                .get(`${BACKEND_URL}/orders`, {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                })
                .then((res) => {
                    console.log(res.data, "order");
                    setAllOrder(res?.data);
                })
                .catch((err) => console.log(err));
        };
        GetAllOrder();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const handleStatusChange = (orderId, newStatus) => {
        axios
            .put(
                `${BACKEND_URL}/orders/${orderId}`,
                { status: newStatus },
                {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                }
            )
            .then((res) => {
                setAllOrder((prevOrders) =>
                    prevOrders.map((order) =>
                        order.id === orderId ? { ...order, status: newStatus } : order
                    )
                );
            })
            .catch((err) => console.log(err));
    };

    const handleViewProductClick = (products) => {
        setSelectedProduct(products);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedProduct(null);
    };

    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Orders"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                    />
                    {AllOrder && AllOrder?.length >= 1 ? (
                        <TableContainer>
                            <Box component={Table} alignItems="center" marginBottom="0!important">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order ID</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Order Date</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Email</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Phone Number</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Profit Margin</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>City</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Total Amount</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Products</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>Status</TableCell>
                                        <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootHead}>View Product</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {AllOrder?.map((order) => (
                                        <TableRow key={order.orderId}>
                                            <TableCell className={classes.tableCellRoot + " " + classes.tableCellRootBodyHead}>
                                                <Box alignItems="center" display="flex">
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box fontSize=".875rem" component="span">{order.orderId}</Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{formatDate(order?.orderDate)}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.email}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.phoneNumber}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.profitMargin || 0}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.city}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.totalAmount}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{order.products?.length}</Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Select
                                                    value={order.status}
                                                    onChange={(e) => handleStatusChange(order.orderId, e.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {statusOptions.map((status) => (
                                                        <MenuItem key={status} value={status}>
                                                            {status}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell className={classes.tableCellRoot}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    <Button onClick={() => handleViewProductClick(order.products)}>View Product</Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Box>
                        </TableContainer>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Order to Show</h1>
                        </div>
                    )}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={3} color="primary" variant="outlined" />
                    </Box>
                </Card>
                
                {/* Modal for viewing products */}
                <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                    <DialogTitle>Order Products</DialogTitle>
                    <DialogContent>
                        {selectedProduct ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedProduct.map((product) => (
                                            <TableRow key={product.id}>
                                                <TableCell>{product.id}</TableCell>
                                                <TableCell>{product.title}</TableCell>
                                                <TableCell>{product.quantity}</TableCell>
                                                <TableCell>{product.price}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <p>No products to display</p>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

export default withAppContext(Order);
