import {
	Box,
	Card,
	CardContent,
	FilledInput,
	FormControl,
	FormGroup,
	FormLabel,
	IconButton,
	makeStyles,
	useTheme
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PhotoCamera } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import Loader from "../../components/Loader/loader";
import { withAppContext } from "../../contextApi/Auth";
import { BACKEND_URL, BACKENDImage_URL } from "../../env";

const useStyles = makeStyles(componentStyles);

function CreateUpdateCategory({ open, handleClose, UpdateId, GetAllCategories }) {
	const classes = useStyles();
	const theme = useTheme();
	const [Loading, setLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [Image, setImage] = useState(null);
	// console.log(UpdateId);

	const handleImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
			setSelectedImage(URL.createObjectURL(event.target.files[0]));
		}
	};
	function GetCategoryInfo() {
		axios
			.get(`${BACKEND_URL}/categories/${UpdateId}`, {
				headers: {
					token: localStorage.getItem("Token"),
				},
			})
			.then((res) => {
				console.log(res.data);

				setName(res.data.getCategory.name);
				setImage(res.data.getCategory.Image);
			})
			.catch((res) => console.log(res));
	}

	useEffect(() => {
		GetCategoryInfo();
	}, [UpdateId]);

	const [Name, setName] = useState('');

	const handleCreateSubmit = (e) => {
		if (Name.length) {
			setLoading(true);
			// const Obj = {
			// 	name: Name
			// };
			console.log(Name);

			const formData = new FormData();
			formData.append('name', Name);
			formData.append('Image', Image);
			const config = {
				method: UpdateId ? "put" : "post",
				url: UpdateId ? `${BACKEND_URL}/categories/${UpdateId}` : `${BACKEND_URL}/categories`,
				data: formData,
				headers: {
					token: localStorage.getItem("Token")
				}
			};
			axios(config)
				.then((res) => {
					GetAllCategories();
					handleClose();
					setName(' ');
					setSelectedImage("")
					setImage("")
					setLoading(false);
					console.log(res);
					toast.success(`Successfully added ${Name} category`, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
					});
				})
				.catch((res) => {
					setLoading(false);
					toast.error(`Invalid error`, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
					});
				});
		} else {
			toast.error("Please Fill This category field", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
		}
	};
	const enterLogin = (e) => {
		console.log(e);
		if (e?.keyCode === 13) {
			handleCreateSubmit();
		}
	}
	return (
		<div>
			<Dialog open={open} onClose={() => handleClose()} aria-labelledby="draggable-dialog-title">
				<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
					Create Category
				</DialogTitle>
				<DialogContent>
					<Card classes={{ root: classes.cardRoot }}>
						<Box component={CardContent} classes={{ root: classes.ptMd4 }} paddingTop="0!important">
							<FormGroup>
								<FormLabel>Name</FormLabel>
								<FormControl
									variant="filled"
									component={Box}
									width="100%"
									marginBottom="1rem!important"
								>
									<Box
										paddingLeft="0.75rem"
										paddingRight="0.75rem"
										component={FilledInput}
										autoComplete="off"
										type="text"
										value={Name}
										onChange={(e) => setName(e.target.value)}
										onKeyDown={(e) => enterLogin(e)}

									/>
								</FormControl>
								<FormLabel>Upload Image</FormLabel>
								<FormControl
									variant="filled"
									component={Box}
									width="100%"
									marginBottom="1rem!important"
								>
									<div className={classes.uploadButton}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="contained-button-file"
											type="file"
											onChange={handleImageChange}
										/>
										<label htmlFor="contained-button-file">
											<IconButton color="primary" aria-label="upload picture" component="span">
												<PhotoCamera />
											</IconButton>
										</label>
									</div>
									{selectedImage || Image && <img src={selectedImage ? selectedImage : `${BACKENDImage_URL}${Image}`} alt="Selected" className={classes.imagePreview} />}
								</FormControl>
							</FormGroup>
						</Box>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => handleClose()}>
						Cancel
					</Button>
					<Button onClick={() => handleCreateSubmit()}>
						{Loading ? <Loader cumtomStyle={{ position: "unset", }} /> : UpdateId ? "Update Category" : "Create Category"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default withAppContext(CreateUpdateCategory);
