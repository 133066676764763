import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupAdd from "@material-ui/icons/GroupAdd";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import PieChart from "@material-ui/icons/PieChart";

import moment from "moment";
// core components
import CardStats from "../../components/Cards/CardStats.js";

import componentStyles from "../../assets/theme/components/header.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import { BACKEND_URL } from "../../env.js";
import axios from "axios";

const useStyles = makeStyles(componentStyles);

const Header = ({ getUser, UserData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [AllOrder, setAllOrder] = useState([]);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [cancelledOrders, setCancelledOrders] = useState(0);

  useEffect(() => {
    const GetAllOrder = () => {
      axios
        .get(`${BACKEND_URL}/orders`, {
          headers: {
            token: localStorage.getItem("Token"),
          },
        })
        .then((res) => {
          console.log(res.data, "order");
          const orders = res?.data;
          setAllOrder(orders);

          // Filter orders based on status
          const completedCount = orders.filter(order => order.status === 'delivered').length;
          const cancelledCount = orders.filter(order => order.status === 'cancelled').length;

          setCompletedOrders(completedCount);
          setCancelledOrders(cancelledCount);
        })
        .catch((err) => console.log(err));
    };
    GetAllOrder();
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xl={4} lg={6} xs={12}>
                <CardStats
                  subtitle="Users"
                  title={UserData?.length || 0}
                  icon={InsertChartOutlined}
                  color="bgError"
                  footer={
                    <>
                      <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.success.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        {UserData.length || 0}
                      </Box>
                      <Box component="span" whiteSpace="nowrap">
                        Users
                      </Box>
                    </>
                  }
                />
              </Grid>
              <Grid item xl={4} lg={6} xs={12}>
                <CardStats
                  subtitle="All Orders"
                  title={AllOrder.length || 0}
                  icon={PieChart}
                  color="bgWarning"
                  footer={
                    <>
                      <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.success.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        {AllOrder.length || 0}
                      </Box>
                    </>
                  }
                />
              </Grid>
              <Grid item xl={4} lg={6} xs={12}>
                <CardStats
                  subtitle="Completed Orders"
                  title={completedOrders}
                  icon={CheckCircleIcon}
                  color="bgSuccess"
                  footer={
                    <>
                      <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.success.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        {completedOrders}
                      </Box>
                    </>
                  }
                />
              </Grid>
              <Grid item xl={4} lg={6} xs={12}>
                <CardStats
                  subtitle="Cancelled Orders"
                  title={cancelledOrders}
                  icon={CancelIcon}
                  color="bgInfo"
                  footer={
                    <>
                      <Box
                        component="span"
                        fontSize=".875rem"
                        color={theme.palette.success.main}
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          component={ArrowUpward}
                          width="1.5rem!important"
                          height="1.5rem!important"
                        />{" "}
                        {cancelledOrders}
                      </Box>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withAppContext(Header);
