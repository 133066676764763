import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components

import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import CreateTags from "./CreateTags";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import { BACKEND_URL } from "../../env.js";
import { CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { toast } from "react-toastify";

const useStyles = makeStyles(componentStyles);

const Tags = ({ getTags, deleteTags, ...props }) => {
    const classes = useStyles();
    const [Loading, setLoading] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [UpdateData, setUpdateData] = useState("");
    const [Tags, setTags] = useState([]);
    const [openCsvModel, setOpenCsvModel] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const handleClickOpen = () => {
        setOpenModel(true);
    };

    const handleClose = () => {
        setUpdateData("");
        setOpenModel(false);
    };

    useEffect(() => {
        if (props.TagsData) {
            setTags(props.TagsData)
        }
        console.log(props.TagsData)
    }, [props.TagsData])

    useEffect(() => {
        getTags()
    }, [])

    const handleFileChange = (e) => {
		setCsvFile(e.target.files[0]);
	};

	const handleUpload = async () => {
		if (!csvFile) {
			toast.error("Please select a CSV file first");
			return;
		}

		const formData = new FormData();
		formData.append('csv', csvFile);

		try {
			await axios.post(`${BACKEND_URL}/tag/import`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					token: localStorage.getItem("Token"),
				},
			});
			toast.success("CSV uploaded successfully!");
			getTags()
			setOpenCsvModel(!openCsvModel)
			setCsvFile("")

			// refreshCategories(); // Refresh the categories list after upload
		} catch (error) {
			toast.error("Failed to upload CSV");
		}
	};

    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Tag"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                        action={
                            <Box>
                                <Button variant="outlined" onClick={handleClickOpen}>
                                    Create Tag
                                </Button>
                                <Button variant="outlined" style={{ marginLeft: "10px" }} onClick={() => setOpenCsvModel(!openCsvModel)}>
                                    Import CSV
                                </Button>
                            </Box>
                        }
                    ></CardHeader>

                    <TableContainer>
                        <Box component={Table} alignItems="center" marginBottom="0!important">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Tag
                                    </TableCell>

                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Tags.length && Tags?.map((a, i) => {
                                    return (
                                        <TableRow key={a.id}>
                                            <TableCell
                                                classes={{
                                                    root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                <Box alignItems="center" display="flex">
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box fontSize=".875rem" component="span">
                                                            {i + 1}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.name}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box display="flex" alignItems="center">
                                                    <Box
                                                        component="span"
                                                        marginRight=".5rem"
                                                        onClick={() => {
                                                            setOpenModel(true);
                                                            setUpdateData(a);
                                                        }}
                                                    >
                                                        <Edit style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                                                    </Box>
                                                    <Box
                                                        component="span"
                                                        marginRight=".5rem"
                                                        onClick={() =>
                                                            Swal.fire({
                                                                title: "Are you sure?",
                                                                text: "You won't be able to revert this!",
                                                                icon: "warning",
                                                                showCancelButton: true,
                                                                confirmButtonTag: "#3085d6",
                                                                cancelButtonTag: "#d33",
                                                                confirmButtonText: "Yes, delete it!",
                                                                preConfirm: () => {
                                                                    axios
                                                                        .delete(
                                                                            `${BACKEND_URL}/tag/${a?.id}`,
                                                                            {
                                                                                headers: {
                                                                                    token: localStorage.getItem("Token")
                                                                                }
                                                                            }
                                                                        )
                                                                        .then((res) => {
                                                                            console.log(res);
                                                                            getTags();
                                                                        })
                                                                        .catch((res) => console.log(res));
                                                                }
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    Swal.fire(
                                                                        "Deleted!",
                                                                        "Your Tag has been deleted.",
                                                                        "success"
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    >
                                                        <Delete style={{ Tag: "red", width: "20px", height: "20px", cursor: "pointer" }} />
                                                    </Box>
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Box>
                    </TableContainer>
                    {/* ) : (

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Tag to Show</h1>
                        </div>
                    )} */}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={3} Tag="primary" variant="outlined" />
                    </Box>
                </Card>
                <CreateTags getTags={getTags} open={openModel} handleClose={handleClose} UpdateData={UpdateData} setUpdateData={setUpdateData} />
            </Container>
            <Dialog open={openCsvModel} onClose={() => setOpenCsvModel(!openCsvModel)} aria-labelledby="draggable-dialog-title">
                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    Import CSV
                </DialogTitle>
                <DialogContent>
                    <Card classes={{ root: classes.cardRoot }}>
                        <Box component={CardContent} classes={{ root: classes.ptMd4 }}>
                            <input
                                accept=".csv"
                                style={{ display: 'none' }}
                                id="csv-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="csv-file">
                                <IconButton color="primary" aria-label="upload CSV" component="span" style={{ fontSize: "20px" }}>
                                    <CloudUpload style={{ fontSize: "30px" }} fontSize='30px' />
                                </IconButton>
                            </label>
                            <Button onClick={handleUpload} variant="contained" color="primary">
                                Submit CSV File
                            </Button>
                        </Box>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpenCsvModel(!openCsvModel)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withAppContext(Tags);
