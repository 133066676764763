import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { CloudUpload } from '@material-ui/icons';
import axios from 'axios';
import { toast } from "react-toastify";
import { BACKEND_URL } from '../../env';
import componentStyles from "../../assets/theme/views/admin/tables.js";
const useStyles = makeStyles(componentStyles);
const UploadCSV = ({ open, handleClose, GetAllCategories }) => {
    const classes = useStyles();
    const [csvFile, setCsvFile] = useState(null);

    const handleFileChange = (e) => {
        setCsvFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!csvFile) {
            toast.error("Please select a CSV file first");
            return;
        }

        const formData = new FormData();
        formData.append('csv', csvFile);

        try {
            await axios.post(`${BACKEND_URL}/categories/upload-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success("CSV uploaded successfully!");
            GetAllCategories()
            handleClose()

            // refreshCategories(); // Refresh the categories list after upload
        } catch (error) {
            toast.error("Failed to upload CSV");
        }
    };

    return (
        <Dialog open={open} onClose={() => handleClose()} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                Import CSV
            </DialogTitle>
            <DialogContent>
                <Card classes={{ root: classes.cardRoot }}>
                    <Box component={CardContent} classes={{ root: classes.ptMd4 }}>
                        <input
                            accept=".csv"
                            style={{ display: 'none' }}
                            id="csv-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="csv-file">
                            <IconButton color="primary" aria-label="upload CSV" component="span" style={{ fontSize: "20px" }}>
                                <CloudUpload style={{ fontSize: "30px" }} fontSize='30px' />
                            </IconButton>
                        </label>
                        <Button onClick={handleUpload} variant="contained" color="primary">
                            Submit CSV File
                        </Button>
                    </Box>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleClose()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

        // <div>
        // 	<input
        // 		accept=".csv"
        // 		style={{ display: 'none' }}
        // 		id="csv-file"
        // 		type="file"
        // 		onChange={handleFileChange}
        // 	/>
        // 	<label htmlFor="csv-file">
        // 		<IconButton color="primary" aria-label="upload CSV" component="span">
        // 			<CloudUpload />
        // 		</IconButton>
        // 	</label>
        // 	<Button onClick={handleUpload} variant="contained" color="primary">
        // 		Upload CSV
        // 	</Button>
        // </div>
    );
};

export default UploadCSV;
