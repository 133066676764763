import React, { useEffect } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components

// core components
import Header from "../../components/Headers/Header.js";

import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

import "sweetalert2/src/sweetalert2.scss";

const useStyles = makeStyles(componentStyles);

const User = ({ getUser, UserData, ...props }) => {
    const classes = useStyles();


    useEffect(() => {
        getUser()
    }, [])

    return (
        <>
            <Header />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Users"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                    // action={
                    // <Button variant="outlined" onClick={handleClickOpen}>
                    //     Create Tag
                    // </Button>
                    // }
                    ></CardHeader>

                    <TableContainer>
                        <Box component={Table} alignItems="center" marginBottom="0!important">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Email
                                    </TableCell>

                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Whatsapp
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Store Name
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Cnic
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Account Title
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCellRoot + " " + classes.tableCellRootHead
                                        }}
                                    >
                                        Account Number
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UserData.length && UserData?.map((a, i) => {
                                    return (
                                        <TableRow key={a.id}>
                                            <TableCell
                                                classes={{
                                                    root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead
                                                }}
                                                component="th"
                                                variant="head"
                                                scope="row"
                                            >
                                                <Box alignItems="center" display="flex">
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box fontSize=".875rem" component="span">
                                                            {a.id}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.email}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.whatsapp}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.storeName}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.cnic}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.accountTitle}
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">
                                                    {a?.accountNumber}
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Box>
                    </TableContainer>
                    {/* ) : (

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <h1>No Tag to Show</h1>
                        </div>
                    )} */}
                    <Box
                        classes={{ root: classes.cardActionsRoot }}
                        component={CardActions}
                        justifyContent="flex-end"
                    >
                        <Pagination count={3} Tag="primary" variant="outlined" />
                    </Box>
                </Card>
                {/* <CreateTags getTags={getTags} open={openModel} handleClose={handleClose} UpdateData={UpdateData} setUpdateData={setUpdateData} /> */}
            </Container>
        </>
    );
};

export default withAppContext(User);
