import React, { useEffect, useState } from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// @material-ui/icons components
import Edit from "@material-ui/icons/Edit";
// core components
import Header from "../../components/Headers/Header.js";
import componentStyles from "../../assets/theme/views/admin/tables.js";
import { withAppContext } from "../../contextApi/Auth/index.js";
import axios from "axios";
import "sweetalert2/src/sweetalert2.scss";
import { BACKEND_URL } from "../../env.js";

const useStyles = makeStyles(componentStyles);

const Reward = () => {
    const classes = useStyles();
    const [AllRewards, setAllRewards] = useState([]);
    const [statusOptions] = useState(["pending", "completed", "cancelled"]); // Update with your status options

    useEffect(() => {
        const GetAllReward = () => {
            axios
                .get(`${BACKEND_URL}/reward`, {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                })
                .then((res) => {
                    console.log(res.data, "reward");
                    setAllRewards(res?.data?.getAllRewards);
                })
                .catch((err) => console.log(err));
        };
        GetAllReward();
    }, []);

    const handleStatusChange = (rewardId, newStatus) => {
        axios
            .put(
                `${BACKEND_URL}/reward/${rewardId}`,
                { status: newStatus },
                {
                    headers: {
                        token: localStorage.getItem("Token"),
                    },
                }
            )
            .then((res) => {
                // Update the state with the new status
                setAllRewards((prevRewards) =>
                    prevRewards.map((reward) =>
                        reward.id === rewardId ? { ...reward, status: newStatus } : reward
                    )
                );
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <Card classes={{ root: classes.cardRoot }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title="Available Rewards"
                        titleTypographyProps={{
                            component: Box,
                            marginBottom: "0!important",
                            variant: "h3"
                        }}
                    />
                    {AllRewards && AllRewards.length >= 1 ? (
                        <TableContainer>
                            <Box component={Table} alignItems="center" marginBottom="0!important">
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            ID
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Store Name
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Email
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Whatsapp
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Account Name
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Account Number
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Amount
                                        </TableCell>
                                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootHead }}>
                                            Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {AllRewards.map((reward) => (
                                        <TableRow key={reward.id}>
                                            <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead }} component="th" variant="head" scope="row">
                                                <Box alignItems="center" display="flex">
                                                    <Box display="flex" alignItems="flex-start">
                                                        <Box fontSize=".875rem" component="span">{reward.id}</Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.storeName}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.email}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.whatsapp}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.accountName}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.accountNumber}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Box paddingTop=".35rem" paddingBottom=".35rem">{reward.amount}</Box>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.tableCellRoot }}>
                                                <Select
                                                    value={reward.status}
                                                    onChange={(e) => handleStatusChange(reward.id, e.target.value)}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {statusOptions.map((status) => (
                                                        <MenuItem key={status} value={status}>
                                                            {status}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Box>
                        </TableContainer>
                    ) : (
                        <div style={{ width: "100%", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                            <h1>No Reward to Show</h1>
                        </div>
                    )}
                    <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end">
                        <Pagination count={3} color="primary" variant="outlined" />
                    </Box>
                </Card>
                {/* <CreateUpdateOrder open={openModel} handleClose={handleClose} UpdateId={UpdateId} /> */}
            </Container>
        </>
    );
};

export default withAppContext(Reward);
