import React from "react";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "../../assets/theme/components/user-header.js";
import { withAppContext } from "../../contextApi/Auth/index.js";

const useStyles = makeStyles(componentStyles);

const UserHeader = ({}) => {
	const classes = useStyles();
	const theme = useTheme();
	return (
		<>
			<Box
				paddingTop="3rem"
				paddingBottom="8rem"
				alignItems="center"
				display="flex"
				className={classes.wrapperBox}
				minHeight="600px"
				position="relative"
			>
				<Box
					position="absolute"
					top="0"
					left="0"
					width="100%"
					height="100%"
					className={classes.overlayBox}
				/>
				<Container
					display="flex"
					alignItems="center"
					maxWidth={false}
					component={Box}
					classes={{ root: classes.containerRoot }}
				>
					<Grid container>
						<Grid item xs={12} md={10} lg={7}>
							<Typography variant="h1" classes={{ root: classes.typographyRootH1 }}>
								DesetsDropshipper Admin Panel
							</Typography>
							<Box
								component="p"
								marginBottom="3rem"
								color={theme.palette.white.main}
								lineHeight="1.7"
								fontSize="1rem"
							>
								This is your Product creation page. You can add your product here
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default withAppContext(UserHeader);
